import { reactive, toRef } from "vue";
import { IUnknownObject } from "@/@types/common";

interface ITransmitterProperties {
  [key: string]: TProperty;
}

type TProperty = any

const state: ITransmitterProperties = reactive({});

export async function insert(key: string, value: any) {
    return new Promise((resolve) => {
        Object.defineProperty(state, key, {
            value: value,
            enumerable: true,
            configurable: true,
        });
        resolve(true);
    });
}

export function select(key: string, defaults: IUnknownObject = {}): TProperty {
    return toRef(state, key).value;
}
