import { reactive, ref, Ref, watch } from "vue";
import { IUnknownObject } from "@/@types/common";

interface IModal {
    name: string,
    isShow: Boolean,
    loader: Boolean,
    data: IUnknownObject,
    handle: any,
    resolve: any,
    reject: any,
    open(data?: IUnknownObject): Promise<any>,
    close(): void,
    ok(data?: IUnknownObject): void,
    cancel(data?: IUnknownObject): void,
}
export const modals: any = reactive({});

export const countOfOpenModals = ref(0);

export function isOpen(name: string) {
    return modals[name].isShow;
}

/**
 * Инициализация модалки
 * @param {string} name - Названия модалки
 */
export function initModal(name: string) {
    const handle: Ref = ref(null);

    Object.defineProperty(modals, name, {
        value: {
            name: name,
            idShow: false,
            loader: false,
            data: {},
            handle: handle,
            open: open,
            close: close,
            ok: ok,
            cancel: cancel,
            resolve: false,
            reject: false,
        },
        enumerable: true,
        configurable: true,
    });

    let modal: IModal = modals[name];

    async function open(data: IUnknownObject = {}) {
        countOfOpenModals.value++;
        modal.loader = true;
        modal.data = data;
        modal.isShow = true;
        await new Promise(resolve => watch(() => modal.handle, () => resolve(true)));
        modal.loader = false;
        return new Promise((ok, fail) => {
            modal.resolve = ok;
            modal.reject = fail;
        });
    }

    function close() {
        countOfOpenModals.value--;
        modal.isShow = false;
    }

    function ok(data: IUnknownObject) {
        close();
        modal.resolve(data);
    }

    function cancel(data: IUnknownObject) {
        close();
        modal.reject(data);
    }

    return {
        modal,
        handle,
    };
}

export function useModal(name: string): IModal {
    return modals[name];
}
